
.orderBackBtn{
    background-color: #276599 !important;
    color: white;
    padding: 10px;
    margin-top: 20px !important;
    border-radius: 5px;
} 


.dispatchTbl{
    padding-bottom: 20px;
}

.dispatchItemsTbl{
    padding-bottom: 20px;
}

.exportToCSVBtn {
    background-color: #276599;
    height: fit-content;
     padding: 10px;
    border-radius: 5px;
    margin-left: 25px !important;
    margin-top: 20px !important;
    color: white; 
}

a.exportToCSVBtn:hover {
    color: white;
}

.actionBtns{
    display: flex;
    flex-direction: row;
}

main.main.showOrderDetails {
    background: url('../../../media/dispatchBg.png');
}

/* Loading component */
.loadingSpinComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.custom-marker {
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
  }

  .map-container {
    position: relative;
    z-index: 0;
    width:50%
  }
  
  .map {
    position: relative;
    z-index: 1;
  }

  .leaflet-popup.leaflet-zoom-animated{
    bottom: 22px !important;
    left: -78px !important;
  }

  .leaflet-popup-content {
    padding: 10px;
    width: 154px !important;
}

.viewContainer {
    display: flex;
}

video.videoContainer {
    width: 130%;
    height: 57vh;
    margin-left: 30px !important;
    margin-top: 20px !important;
}

.map-container {
    position: relative;
    z-index: 0;
    width: 45%;
}

.imageViewContainer{
    position: relative;
    z-index: 0;
    width: 50%;
    padding-top: 20px;
}

img.imageUrlAll {
    height: 57vh;
    max-width: 100%;
    margin-left: 80px !important;
}