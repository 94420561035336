
.toDoListContainer {
    margin-top: -40px !important;
}

.itemList {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.capturedItems {
    margin-top: 15px !important;
    margin-left: 15px !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.sumOrdBtn.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
    z-index: 1;
}

