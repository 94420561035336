.orderBackBtn{
    background-color: firebrick;
    color: white;
    padding: 10px;
    margin-top: 20px !important;
    border-radius: 5px;
} 

a.csvBtn {
    color: rgb(255, 255, 255);
    background-color: firebrick;
    padding: 10px;
    margin-top: 20px !important;
    margin-left: 0px !important;
    border-radius: 5px;
}

a.csvBtn:hover{
color: white;
}

.exportToCSVBtn {
    background-color: #276599;
    height: fit-content;
     padding: 10px;
    border-radius: 5px;
    margin-left: 25px !important;
    margin-top: 20px !important;
    color: white; 
}

a.exportToCSVBtn:hover {
    color: white;
}

main.main.showDelDetails {
    background: url('../../../media/receivingBg.png');
}

/* Loading component */
.loadingSpinComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.deliveriesItemsTbl {
    padding-top: 20px;
}

.actionBtns {
    display: flex;
    flex-direction: row;
}

.map-container {
    position: relative;
    z-index: 0;
    width:50%;
    padding-top: 20px;
  }
  
  .map {
    position: relative;
    z-index: 1;
  }

  .leaflet-popup.leaflet-zoom-animated{
    bottom: 22px !important;
    left: -78px !important;
  }

  .leaflet-popup-content {
    padding: 10px;
    width: 154px !important;
}

.mapContainer {
    display: flex;
}