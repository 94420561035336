.filterContainer {
    display: flex;
    padding-bottom: 20px;
    width: 20%;
}

.filterTitle {
    display: flex;
    flex: 1;
}

.filterOptions {
    display: flex;
    flex: 1;
}