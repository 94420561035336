.exportPDFBtn {
    background-color: #276599;
    color: white;
    padding: 10px;
    margin-top: 20px !important;
    border-radius: 5px;
    margin-left: 25px !important;
}

/* -----------MODAL------------- */

.modal-container {
    width: 429px;
    margin-left: 500px !important;
    margin-top: 200px !important;
    background-color: white;
    padding: 20px;
    height: 180px !important;
    border-radius: 5px;
}

button.modalCloseBtn{
    margin-top: 20px !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

select.modalDropdownMenu {
    margin-bottom: 25px !important;
}

.pdf-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  page-break-after: always;
  }
  