/* th.DateColWidth, td.DateColWidth{
    display: flex;
    flex: 1;
  }

  th.siteNoColWidth, td.siteNoColWidth{
    display: flex;
    flex: 1;
  }

  th.ProgessColWidth, td.ProgessColWidth{
    display: flex;
    flex: 6;
    width: 60px;
  } */

  tr.clickable-row {
    cursor: pointer;
}