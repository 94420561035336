
.title-bar {
    display: flex;
    background: #01003A !important;
    width: 100% !important;
    height: 85px;
    position: fixed;
    flex: 1;
}

.title-bar-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;

}

.title-bar-middle {
    display: flex;
    flex: 4 1;
    min-width: 850px;
    max-width: 850px;
    align-content: center;
    flex-wrap: wrap;
}

.title-bar-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

a.logoutBtn, a.logoutBtn:hover {
    color: firebrick;
}

.logoSize {
    width: 35%;
    margin-left: 20px !important;
    /* margin-top: -10px !important; */
    
}

img.logoutBtn {
    padding-left: 25px;
    cursor: pointer;
}

.fstColumn {
    display: flex;
    flex: 1;
    flex-direction: row;
}
.badgeCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 20px;
}

.titleCol {
    display: flex;
    align-items: center;
    flex: 1.5;
}

a.titleColLink {
    color: white;
}

.sndColumn {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.subCol {
    display: flex;
    flex-direction: column;
    flex: 1;
}


.css-jcn4dz-MuiBadge-badge{
    background-color: red !important;
}

.my-badge {
    background-color: #dba700;
}