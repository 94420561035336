.table{
    display: flex;
    text-align: center;
}

.orderDetailTitleColumn1{
    background-color: lightcoral;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    border: #646464a8 1px solid;

}

.orderDetailTitleColumn2{
    
    background-color: lightcoral;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    border: #646464a8 1px solid;
}

.orderDetailDataColumn1{
    padding-top: 10px;
}
.orderDetailDataColumn2{
    
    margin-left: 10px !important;
    padding-top: 10px;
}

.addressBackBtn{
    background-color: firebrick;
    color: white;
    padding: 10px;
    margin-top: 20px !important;
    border-radius: 5px;
}

main.main.showDeliveryAddress {
    background-color: #cee0b3;
}