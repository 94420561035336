
  .siteNoCon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .siteNoInput {
    padding-left: 20px;
  }
  