*{
  margin: 0;
  padding: 0;
}

/*-----------Filter Section---------*/

.filter{
  padding-bottom: 20px !important;
  
 }

 .row {
  display: flex;
}

.column1 h5{
  
  width: 150px;
}

.column2 {
  margin-left: 7px !important;
  
}

.column3 h5{
  width: 101px;
  
}

.column3{
  padding-left: 20px ;
}

.column4 {
  margin-left: -33px !important;
  
  
}
.column5 {
  
  margin-left: 10px !important;
  
}
.column6 {
  
  margin-left: 8px !important;
  
}

.searchInput{
  height: 32px !important;
  width: 150px !important;
}

.siteBtn{
  background-color: firebrick !important;
  width: 50px !important;
  color: white !important;
  padding: 0px !important;
}

.BtnSearch{
  background-color: firebrick !important;
  width: 150px !important;
  color: white !important;
  margin-top: 10px !important;
 }
/*-------------Table-------------*/
table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse; 
    width: 95% !important;
   
  }

  
   table td, table th {
    
    border-bottom: 1px solid rgb(105, 105, 105 )!important;
    padding: 8px;
  } 
  
    table tr:nth-child(even){
    background-color: #ffffff !important; 
    color: #000000;
  } 
  
  table tr:hover {background-color: #ffffff !important; } 
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center !important;
    background-color: #01003A !important;
    color: rgb(255, 255, 255) !important;
  }

  td{
  text-align: center;
  }

 th.orderNoWidth {
  width: 120px;
  }

 th.dateTimeWidth {
  width: 130px;
  }
  
  th.delAddNoWidth {
    width: 130px;
  }
  




 /*----------PAGINATION----------*/
 .paginationBttns{
  width: 84%;
  height: 20px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 15px !important;
 }

 .paginationBttns a{
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  cursor: pointer;

 }

 .paginationBttns a:hover{
  color: white;
  background-color: firebrick;
  color: inherit;
 }

 .paginationActive a{
  color: white;
  background-color: firebrick;
  
 }

 a:focus {
  color: inherit !important;
}

.pagination {
  display: flex;
  margin-left: 200px !important;
 
}

.pagination__link {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.pagination__link--disabled {
  color: #ccc;
  cursor: default;
}

.pagination__link--active {
  background-color: #007bff;
  color: #000000;
}

select.pageRows{
  font-size: small !important;
  width: 4% !important;
  height: 2.4rem !important;
  margin: 0 !important;
}

.rows-per-page {
  flex-direction: row;
  display: flex;
  margin-top: 15px !important;
}

label{
  padding-right: 12px !important; 
}
/* .ReactModal__Overlay{
  background: pink;
} */
 
.NoMatchFoundMsg{
  color: red;
  font-size: large;
  width: 360px;
  padding-top: 10px;

}

