.orderBackBtn{
    background-color: firebrick;
    color: white;
    padding: 10px;
    margin-top: 20px !important;
    border-radius: 5px;
} 

.exportToCSVBtn {
    background-color: #276599;
    height: fit-content;
     padding: 10px;
    border-radius: 5px;
    margin-left: 25px !important;
    margin-top: 20px !important;
    color: white; 
}

a.exportToCSVBtn:hover {
    color: white;
}

.actionBtns{
    display: flex;
    flex-direction: row;
}

main.main.showReturnedDetails {
    background: url('../../../media/returnsBg.png');

}


