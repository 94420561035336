.App {
  width: 65%;
}

.questionContainer {
  display: flex;
  padding: 25px;
  flex-direction: column;

   
  overflow: hidden;
  transition: transform 0.3s ease, opacity 0.6s ease; /* Use transform for smooth animation */
  opacity: 1;
  transform: scaleY(1); 
}

.questionContainer.collapsed {
  max-height: 35px;
  transition-timing-function: ease-in-out;
  width: 1120px;
}

.questionContainerStoreAudit{
  display: flex;
  padding: 25px;
  flex-direction: column;
}
  
  .passedTable {
    display: flex;
    padding: 25px;
    flex-direction: column;
  }
  
  .titleHeader {
    display: flex;
    flex: 1;
    border-top: 1px solid;
    border-bottom: 1px solid;
    width: 95%;
    justify-content: center;
    max-height: 30px;
    align-content: center;
    flex-wrap: wrap;
    background-color: #c0c5c9;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
  }

  h4.titleh4 {
    font-size: medium;
}
  
  .titleFooter {
    display: flex;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    width: 95%;
    justify-content: center;
    height: 20px;
    align-content: center;
    flex-wrap: wrap;
    background-color: #c0c5c9;
    font-size: 12px;
  }

  h4.titleFooterH4 {
    font-size: small;
    font-weight: bold;
}
  
  /* table {
    display: flex;
    flex: 1;
    border-collapse: collapse;
    
    flex-direction: column;
  }
  
  thead {
    font-size: 12px;
    text-align: left;
  }
  
  th, td {
    border: 1px solid black;
    padding: 8px;
  }
  
   td {
    display: flex;
    flex: 1;
  }
  
  td.dropdownWidth {
    display: flex;
    flex: 0.3;
  }
  
   tr {
    display: flex;
    flex-direction: row;
    background-color: white;
  } 
  
    thead th {
    background-color: #f2f2f2;
  }
  
  tbody tr:nth-child(even) {
    background-color: #c0c5c9; 
  }
  
  tbody {
    text-align: start;
    font-size: 12px;
    vertical-align: top;
  }  */
  
  .conatinerBackground {
    display: flex;
    flex-direction: column;
      height: 100vh;
  }
  
  
  /* th.questionsColumnWidth, td.questionsColumnWidth{
    display: flex;
    flex: 1;
  }
  https://curly-invention-5jjprxrxx9v2vx4-3000.app.github.dev/deliveries
  th.answersColumnWidth, td.answersColumnWidth {
    display: flex;
    flex: 1;
  }
  
  th.scoreColumnWidth, td.scoreColumnWidth {
    display: flex;
    flex: 0.3;
  }
  
  th.scoreTypeColumnWidth, td.scoreTypeColumnWidth {
    display: flex;
    flex: 0.1 1;
    justify-content: center;
    align-items: center;
  }
  
  th.notesColumnWidth, td.notesColumnWidth {
    display: flex;
    flex: 1;
  }
  
  th.compByColumnWidth, td.compByColumnWidth {
    display: flex;
    flex: 1;
  }
  
  th.siteColumnWidth, td.siteColumnWidth {
    display: flex;
    flex: 1;
  }
  
  th.dateColumnWidth, td.dateColumnWidth {
    display: flex;
    flex: 1;
  }
  
  th.latitudeColumnWidth, td.latitudeColumnWidth {
    display: flex;
    flex: 1;
  }
  
  th.longitudeColumnWidth, td.longitudeColumnWidth {
    display: flex;
    flex: 1;
  } */
  
  
  .actionBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .submitBtnCon {
    padding-top: 30px;
  }
  
  .updateBtnCon {
    padding-top: 30px;
    padding-left: 30px;
  }
  
  button.submitBtn {
    background-color: green;
    color: white;
    padding: 8px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
  }
  
  button.updateBtn {
    background-color: green;
    color: white;
    padding: 8px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
  }

  button.cancelBtn {
    background-color: firebrick;
    color: white;
    padding: 8px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 30px !important;
  }

  button.addQuestionBtn {
    background-color: green;
    color: white;
    padding: 8px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 15px !important;
    margin-bottom: 30px !important;
  }

  button.auditBtn {
    background-color: green;
    color: white;
    padding: 8px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px !important;
}

h4.auditTitle {
  text-align: center;
}
  
  .disabled {
    /* Apply your desired styles for the disabled button here */
    cursor: not-allowed;
    opacity: 0.6; /* Example: Reducing opacity to make it appear greyed out */
  }
  
  .passCircle {
      display: flex;
      width: 25px;
      height: 25px;
      background-image: radial-gradient(
          farthest-corner at 24px 27px,
          rgb(0, 68, 9) 50%,
          rgb(121 163 117) 100%
        );
      border-radius: 50%;
  }
  
  .failCircle {
    display: flex;
    width: 25px;
    height: 25px;
    background-image: radial-gradient(
        farthest-corner at 24px 27px,
        rgb(165, 0, 0) 50%,
        rgb(199, 139, 139) 100%
      );
    border-radius: 50%;
  }
  
  .naCircle {
    display: flex;
    width: 25px;
    height: 25px;
    background-image: radial-gradient(
        farthest-corner at 24px 27px,
        rgb(0, 49, 68) 50%,
        rgb(117, 138, 163) 100%
      );
    border-radius: 50%;
  }
    
  select {
    height: 30px;
  }
  
  .feedbackContainer {
    display: flex;
    flex-direction: column;
  }
  
  .tableFeedback {
    display: flex;
    flex: 1 1;
    border: 1px solid;
    width: 91%;
    margin-top: 80px !important;
    margin-left: 25px !important;
    margin-right: 30px !important;
    margin-bottom: 50px !important;
}
  
  .firstColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid;
  }
  
  .userRow {
    font-weight: 500;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .dateRow {
    font-size: small;
    font-weight: 500;
    padding-left: 10px;
  }
  
  .secondColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
  }
  
  label.headerTile {
    background-color: #3577ab;
    width: 100%;
    text-align: center;
  }
  
  .headerRow {
    width: 100%;
    display: flex;
    flex: 1;
  }
  
  .firstRow {
    background-color: #c0c5c9;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  
  /* .seondRow {
  } */
  
  .thirdRow {
    background-color: #c0c5c9;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  
  /* .forthRow {
  } */
  
  .fifthRow {
    background-color: #c0c5c9;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  
  /* .sixthRow {
  } */
  
  .seventhRow {
    background-color: #c0c5c9;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  
  label.rowData {
    font-size: small;
  }
  
  .findingLogsContainer {
    padding: 35px;
  }
    
  .addSiteBtnContainer {
    display: flex;
    padding-left: 50px;
}
  
  button.addSiteBtn {
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    background-color: #CDFDD4;
  }

  button.startAuditBtn {
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    background-color: #CDFDD4;
   }

  button.auditMaintenBtn {
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
    background-color: #CDFDD4;
    margin-left: 20px !important;
  }
  
  .passedTableTitle {
    display: flex;
    margin-left: 25px;
  }
  
  h4.passedTitleLbl {
    text-decoration: underline;
  }
  
  .submitAuditContainer {
    display: flex;
    margin-top: 30px;
  }
  
  td.dateColumnWidth {
    text-align: left;
}

.btnContainer {
  display: flex;
  flex-direction: row;
}



main.main.auditerPage {
  background-color: beige;
}

table.lpaStoreAnalysisTbl {
  display: flex;
  flex-direction: column;
  flex: 1;
}

thead.lpaStoreAnalysisHeader {
  display: flex;
  flex: 1;
}

tr.lpaStoreAnalysisHeaderRow {
  display: flex;
  flex: 1;
}

th.questionsColumnWidth  {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

td.questionsColumnWidth {
  text-align: left ;
}

th.answersColumnWidth {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

th.scoreColumnWidth {
  display: flex;
  flex: 0.3;
}

th.notesColumnWidth {
  display: flex;
  flex: 1;
}

tbody.lpaStoreAnalysisBody {
  display: flex;
  flex-direction: column;
}

tr.lpaStoreAnalysisBodyRow {
  display: flex;
  flex: 1;
}

td.questionsColumnWidth {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;
}

td.answersColumnWidth {
  display: flex;
  flex-direction: row;
  flex: 1;
}

td.scoreColumnWidth {
  display: flex;
  flex: 0.3;
  flex-direction: row;
}

td.notesColumnWidth {
  display: flex;
  flex: 1;
  flex-direction: row;
}

main.main.findingsLogs {
  background-color: beige;
}

th.typeColWidth {
  display: flex;
}

th.responsesColWidth {
  display: flex;
  flex: 2;
}

th.actionColWidth {
  display: flex;
  flex: 1;
}

th.responPersColWidth {
  display: flex;
  flex: 1;
}

th.dueDateColWidth {
  display: flex;
  flex: 1;
}

td.typeColWidth {
  display: flex;
}

td.responsesColWidth {
  display: flex;
  flex: 2;
  flex-direction: row;
  text-align: left;
}

td.actionColWidth {
  display: flex;
  flex: 1;
}

td.responPersColWidth {
  display: flex;
  flex: 1;
}

td.dueDateColWidth {
  display: flex;
  flex: 1;
}

button.clearBtn {
  background-color: firebrick;
  color: white;
  border: 1px solid white;
  padding: 2px;
}

.selectedOption {
  display: flex;
  flex: 1;
}
.clearBtnCon {
  display: flex;
  flex: 1;
}

/* Add these CSS rules to your stylesheet or style component */
.arrowIcon {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: all 0.4s ease;
  transform: rotate(45deg);
  margin-left: 10px !important;
}

.up {
  transform: rotate(-135deg);
}

.arrowCon {
  display: flex;
  margin-left: 10px;
}

.titleCon {
  display: flex;
  flex: 1;
  justify-content: center;
}

.mapAndGraph {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  width: 95%;
}

.mapContainer {
  display: flex;
  flex: 1;
  padding: 30px;
  min-width: 405px;
}

.chartContainer {
  display: flex;
  flex: 1;
  padding: 30px;
  
}

.siteCont {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.siteTitle {
  padding-right: 30px;
}

.siteTextField {
  width: 280px;
}


/*-------------AUDIT MAINTENANCE PAGE---------------*/

table.auditMainTbl {
  width: 100% !important;
}

th.quesIdColumnWidth, td.quesIdColumnWidth {
  width: 115px;
  text-align: left;
}

th.quesColumnWidth, td.quesColumnWidth {
  width: 700px;
  text-align: left !important;
}

th.actionsColumnWidth {
  width: 130px;
  text-align: left !important;
}

td.actionsColumnWidth {
  width: 130px;
  text-align: center !important;
}

.actionsBtn {
  display: flex;
  justify-content: center;
}

button.editBtn {
  padding-right: 10px;
}

/*----------------EDIT MODAL------------*/
/* .custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal .modal-content {
  background-color: #fff; 
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
  border-radius: 4px;
}

.custom-modal .modal-dialog {
  max-width: 600px; 
  width: 100%;
}

.custom-modal .modal-header {
  background-color: #f8f9fa; 
  border: none;
}

.custom-modal .modal-body {
  padding: 20px;
}

.custom-modal .modal-footer {
  background-color: #f8f9fa; 
  border: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
} */

/****************************************/
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  --bs-modal-margin: 1.75rem;
  --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.fade {
  transition: opacity .15s linear;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.fade .modal-dialog {
  display: flex;
    /* transition: transform .3s ease-out; */
    transform: translate(0,-50px);
    justify-content: center;
    height: 100vh;
    align-items: center;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
} 

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: #0a0a0a;
  opacity: 0.5 !important;
}