.superAdminContainer {
    display: flex;
}

.react-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 1060px;
    max-width: 1060px;
    align-items: center;
}

.transOrd {
    background-color: #CEE3B3;
    z-index: 2;
    height: 15px !important;
    width: 1060px;
    margin-top: -45px !important;
    position: fixed;
}

ul.tabsTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    width: 100%;
    color: white;
    background-color: rgba(1 ,0, 58);
    min-width: 1060px;
    max-width: 1060px;
    z-index: 2;
    /* margin-left: 30px !important; */
    /* margin-top: -30px !important; */
}

.react-tabs__tab-panel--selected {
    display: flex !important;
    flex: 1;
    width: 100%;
}

.createColumn {
    display: flex;
    flex: 1;
    justify-content: center;
}

.viewColumn {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}
/* .companyDetails {
    margin-left: 60px !important;
} */