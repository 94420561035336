h2.loginTitle {
    text-align: center ;
}

.login-form{
    display: flex;
	justify-content: center;
    margin-left: -75px !important;
    /* margin-top: 80px !important; */
}

p.errorMsg {
    padding-top: 25px;
    color: red;
    text-align: center;
}

.loginLogo {
   /*  margin-top: 600px !important;*/
    margin-left: 230px !important;
} 

.loginLogoSize{
    width: 30%;
    margin-top: -10px !important;
}

.logoText {
    color: white;
    margin-left: -25px !important;
    margin-top: 10px !important;
}

.largeLogo {
    margin-left: 210px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-mgobfs-MuiPaper-root{
    padding-left: 40px;
    padding-right: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-direction-xs-column.css-1170vc-MuiGrid-root {
    min-height: 40vh !important;
    margin-top: 27px !important;
    margin-bottom: 27px !important;
}

.MuiContainer-root.MuiContainer-maxWidthSm.css-cuefkz-MuiContainer-root {
    margin-top: 90px !important;
}

.MuiContainer-root.MuiContainer-maxWidthSm.css-1m6pqln {
    padding-top: 100px;
}