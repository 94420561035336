

button.exportPDFBtn {
    background-color: #276599;
    color: white;
    padding: 10px;
    margin-top: 20px !important;
    border-radius: 5px;
    margin-left: 25px !important;
}

.image-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid black;
  }
